<template>
  <el-dialog v-model="view" width="500px" title="添加管理员">
    <el-form label-width="90px" class="principal">
      <el-form-item label="姓名" :required="true">
        <el-input v-model="form.Name"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" :required="true">
        <el-input v-model="form.Mobile"></el-input>
      </el-form-item>
      <el-form-item label="密码" :required="true">
        <el-input v-model="form.Paw" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码" :required="true">
        <el-input v-model="form.Paws" show-password></el-input>
      </el-form-item>
      <el-form-item label="电子邮箱">
        <el-input v-model="form.Email"></el-input>
      </el-form-item>
      <el-form-item label="权限" :required="true">
        <el-select v-model="form.Jurisdiction" placeholder="请选择">
          <el-option
              v-for="(item,index) in Choice"
              :key="index"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="submit">提交</el-button>
        <el-button @click="close">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>


<script>
import {fetchPermission, userAdd} from '../../../api/System/User'

export default {
  data() {
    return {
      Choice: [],
      view: false,
      form: {
        Mobile: "",
        Name: "",
        Email: "",
        Jurisdiction: '',
        Paw: '',
        Paws: ''
      },
    }
  },
  methods: {
    submit() {
      let form = this.form
      userAdd(form).then(res => {
        if (res.code == 0) {
          this.$message.success(res.message);
          this.$parent.getData()
          this.close()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    close() {
      this.view = false
      this.closeForm()
    },
    open() {
      this.view = true
      this.fachdata()
      this.closeForm()
    },
    closeForm(){
      let form=this.form
      for (let item in form){
        form[item]=''
      }
      this.form=form
    },
    fachdata() {
      fetchPermission().then(res => {
        let sum = res.sum
        let Choice = []
        for (let item in sum) {
          let Name = sum[item].Name
          Choice.push(Name)
        }
        this.Choice = Choice
      })
    },
    openLoading(data) {
      if (!data) {
        data = '加载中'
      }
      this.loading = this.$loading({
        lock: true,
        text: data,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },

    closeLoading() {
      this.loading.close();
    },
  }
}
</script>

<style scoped lang="scss">
.fnRed {
  color: #910900;
  font-size: 11px;
}

.batch {
  float: left;
}

.tableClass { //table的class
  ::v-deep .el-table__fixed, ::v-deep .el-table__fixed-right {
    height: 100% !important; //设置高优先，以覆盖内联样式
  }
}


</style>
`