<template>
  <el-dialog v-model="view" width="500px" title="权限管理">
    <el-form label-width="90px" class="principal">
      <el-form-item label="权限" :required="true">
        <el-select v-model="form.Jurisdiction" placeholder="请选择权限" @change="Change"
                   :disabled="query.Jurisdiction=='root'">
          <el-option :label="item" :value="item" v-for="(item,index) in Choice" :key="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="权限路由表">
        <el-tree
            class="data"
            :data="data"
            node-key="id"
            default-expand-all
            :render-content="renderContent"
            :expand-on-click-node="false">
        </el-tree>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
         <el-button @click="save">提交</el-button>
        <el-button @click="close">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>


<script>
import {fetchPermission, UpJurisdiction} from '../../../api/System/User'

export default {
  data() {
    return {
      data: [],
      Permission: [],
      Choice: [],
      query: {
        id: '',
        Jurisdiction: ''
      },
      view: false,
      form: {
        Jurisdiction: ''
      },
    }
  },
  methods: {
    renderContent(h, {node, data}) {
      let remarks = data.subs
      if (remarks) {
        return h("span", {
          class: "custom-tree-node"
        }, h("span", null, node.label), h("i", {
          class: "el-icon-check",
          style: 'color: #00a854;margin-left: 15px;'
        }), h("span", {style: 'color: #00a854;margin-left: 5px;'}, '可用'));
      } else {
        return h("span", {
          class: "custom-tree-node"
        }, h("span", null, node.label), h("i", {
          class: "el-icon-close",
          style: 'color: #910900;margin-left: 15px;'
        }), h("span", {style: 'color: #910900;margin-left: 5px;'}, '不可用'));
      }
    },
    Change(value) {
      let Permission = this.Permission
      for (let item in Permission) {
        if (Permission[item].Name == value) {
          let RoleRouter = Permission[item].RoleRouter
          this.data = RoleRouter
          break;
        }
      }
    },
    fachdata() {
      fetchPermission().then(res => {
        let Jurisdiction = this.query.Jurisdiction
        let sum = res.sum
        let Choice = []
        for (let item in sum) {
          let Name = sum[item].Name
          Choice.push(Name)
        }
        this.Choice = Choice
        this.Permission = sum
        this.form.Jurisdiction = Jurisdiction
        this.Change(Jurisdiction)
      })
    },
    close() {
      this.view = false
    },
    open(id, Jurisdiction) {
      this.view = true
      this.query.id = id
      this.query.Jurisdiction = Jurisdiction
      this.fachdata()
    },
    save() {
      let id = this.query.id
      let Jurisdiction = this.form.Jurisdiction
      if (Jurisdiction == 'Root') {
        this.$message.error('不能选择Root权限');
      } else {
        UpJurisdiction({id, Jurisdiction}).then(res => {
          if (res.code == 0) {
            this.$message.success(res.message);
            this.$parent.getData()
            this.close()
          } else {
            this.$message.error(res.message);
          }
        })
      }
    },
    openLoading(data) {
      if (!data) {
        data = '加载中'
      }
      this.loading = this.$loading({
        lock: true,
        text: data,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },

    closeLoading() {
      this.loading.close();
    },
  }
}
</script>

<style scoped lang="scss">
.fnRed {
  color: #910900;
  font-size: 11px;
}

.data {
  width: 100%;
  height: 400px;
  overflow-y: auto;
}


.batch {
  float: left;
}

.tableClass { //table的class
  ::v-deep .el-table__fixed, ::v-deep .el-table__fixed-right {
    height: 100% !important; //设置高优先，以覆盖内联样式
  }
}


</style>
`
