<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 后台管理人员
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container"  v-loading="loading">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-s-order" @click="handlAdd">添加</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          size="small"
          height="500"
      >
        <el-table-column prop="Name" label="姓名"></el-table-column>
        <el-table-column prop="Mobile" label="手机号码"></el-table-column>
        <el-table-column prop="Email" label="电子邮箱"></el-table-column>
        <el-table-column prop="Jurisdiction" label="权限"></el-table-column>
        <el-table-column prop="NewTime" label="创建时间"></el-table-column>
        <el-table-column label="操作" width="250">
          <template #default="scope">
            <el-button type="text" class="red" @click="BanUser(scope.row.id,scope.row.Ban)" v-if="scope.row.Ban=='0'">禁用账户</el-button>
            <el-button type="text" class="green" @click="BanUser(scope.row.id,scope.row.Ban)" v-else>解封账户</el-button>
            <el-button type="text" @click="opempaw(scope.row.id)">修改密码</el-button>
            <el-button type="text" class="yell" @click="OpenJurisd(scope.row.id,scope.row.Jurisdiction)">权限管理
            </el-button>
            <el-button type="text" class="red" @click="remove(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <UserAdd ref="UserAdd"></UserAdd>
    <UpPaw ref="UpPaw"></UpPaw>
    <Jurisd ref="Jurisd"></Jurisd>
  </div>
</template>

<script>
import {fetchData, removeData, BanUser} from '../../api/System/User'
import UserAdd from './com/UserAdd'
import UpPaw from './com/UpPaw'
import Jurisd from './com/Jurisd'

export default {
  data() {
    return {
      loading: true,
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      tableData: [],
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    BanUser(id, Ban) {
      let text = ''
      if (Ban == '0') {
        text = '封禁'
      } else {
        text = '解封'
      }
      this.$confirm("是否确认" + text + "该管理员用户？", "提示", {
        type: "warning"
      }).then(() => {
        BanUser({id, Ban}).then(res=>{
          if (res.code == 0) {
            this.$message.success(res.message);
            this.getData()
          } else {
            this.$message.error(res.message);
          }
        })
      })
    },
    OpenJurisd(id, Jurisdiction) {
      this.$refs.Jurisd.open(id, Jurisdiction)
    },
    opempaw(id) {
      this.$refs.UpPaw.open(id)
    },
    //删除用户
    remove(id) {
      this.$confirm("是否确认删除该管理员用户？", "提示", {
        type: "warning"
      }).then(() => {
        removeData({id}).then(res => {
          if (res.code == 0) {
            this.$message.success(res.message);
            this.getData()
          } else {
            this.$message.error(res.message);
          }
        })
      })
    },
    //添加管理用户
    handlAdd() {
      this.$refs.UserAdd.open()
    },
    // 分页导航
    handlePageChange(val) {
      this.loading = true
      this.query.pageIndex = val
      this.getData();
    },
    getData() {
      this.loading=true
      fetchData({
        skip: this.query.pageIndex
      }).then(res => {
        this.loading=false
        let bindata = res.sum;
        let number = res.number
        this.query.pageTotal = number
        this.tableData = bindata;
      });
    }
  },
  components: {
    UserAdd,
    UpPaw,
    Jurisd
  }
}


</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.table {
  width: 100%;
  font-size: 14px;
}

.green {
  color: #00a854;
}

.red {
  color: red;
}

.yell {
  color: yellowgreen;
}
</style>
