import request from '../../utils/request';

/**
 * 后台管理人员
 * @param query
 * @returns {AxiosPromise}
 */

//查询数据
export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/User/find',
        method: 'get',
        params: query
    });
};

//删除数据
export const removeData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/User/remove',
        method: 'post',
        data: query
    });
};

//添加用户管理员
export const userAdd = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/User/add',
        method: 'post',
        data: query
    });
};


//修改密码
export const Uppawss = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/User/up/paw',
        method: 'post',
        data: query
    });
};

//查询权限数据
export const fetchPermission = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/User/find/Permission',
        method: 'get',
        params: query
    });
};

//变更用户权限
export const UpJurisdiction = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/User/up/Jurisdiction',
        method: 'post',
        data: query
    });
};

//封禁用户
export const BanUser = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/User/Ban',
        method: 'post',
        data: query
    });
};





